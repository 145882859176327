import { Breakpoints } from "@polestar/component-warehouse-react";

import { type ParsedImageSet } from "../../types";
import convertFocalPointToCssObjectPosition from "../convertFocalPointToCssObjectPosition/convertFocalPointToCssObjectPosition";
import getSourceSet from "../getSourceSet/getSourceSet";

/**
 * Full-height section widths.
 */
const fullHeightSectionWidths = {
  desktopWide: 1440,
  desktop: 1120,
  tablet: 768,
  mobile: 360,
};

const defaultWidths = {
  desktopWide: 1920,
  desktop: 1440,
  tablet: 900,
  mobile: 600,
};

/**
 * Takes the data from `parseDatoImage` to generate source sets, focal points and responsive alt text for your responsive image.
 *
 * @param {Object} params
 * @param {Object} params.imageSet - The URL of each image by device name.
 * @param {Object} params.widths - The width of each image by device name.
 * @param {string} params.deviceName - The current device name to get the correct alt text and object position. Obtain by using `useMediaQuery` from Component Warehouse.
 */
const getResponsiveImageData = ({
  imageSet,
  widths = defaultWidths,
  deviceName,
  qualityDpr1 = 60,
  qualityDpr2 = 35,
  qualityDpr3 = 25,
}: {
  imageSet: ParsedImageSet;
  readonly widths?: {
    desktopWide?: number;
    desktop?: number;
    tablet?: number;
    mobile?: number;
  };
  deviceName?: keyof ParsedImageSet;
  qualityDpr1?: number;
  qualityDpr2?: number;
  qualityDpr3?: number;
}) => {
  const { desktop, desktopWide, tablet, mobile } = imageSet;
  const currentImage = deviceName ? imageSet[deviceName] : desktop;

  const sources = [
    getSourceSet(
      desktopWide,
      widths?.desktopWide ?? defaultWidths.desktopWide,
      Breakpoints.desktopWide,
      qualityDpr1,
      qualityDpr2,
      qualityDpr3
    ),
    getSourceSet(
      desktop,
      widths?.desktop ?? defaultWidths.desktop,
      Breakpoints.desktop,
      qualityDpr1,
      qualityDpr2,
      qualityDpr3
    ),
    getSourceSet(
      tablet,
      widths?.tablet ?? defaultWidths.tablet,
      Breakpoints.tablet,
      qualityDpr1,
      qualityDpr2,
      qualityDpr3
    ),
    getSourceSet(
      mobile,
      widths?.mobile ?? defaultWidths.mobile,
      Breakpoints.mobile,
      qualityDpr1,
      qualityDpr2,
      qualityDpr3
    ),
  ];

  return {
    sources,
    src: desktop.url + `?q=${qualityDpr1}&dpr=1&w=${widths.desktop}`,
    alt: currentImage.alt,
    objectPosition: convertFocalPointToCssObjectPosition(
      currentImage.focalPoint
    ),
  };
};

export { fullHeightSectionWidths };
export default getResponsiveImageData;
