import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Picture,
  Image as CWImage,
  type ImageProps as CWImageProps,
  type ResponsiveThemeData,
  MediaQueries,
  useMediaQuery,
} from "@polestar/component-warehouse-react";

import {
  convertFocalPointToCssObjectPosition,
  getResponsiveImageData,
} from "../helpers";
import { type ParsedImageSet } from "../types";

export interface ImageProps {
  readonly imageSet: ParsedImageSet;
  readonly widths?: {
    desktopWide?: number;
    desktop?: number;
    tablet?: number;
    mobile?: number;
  };
  readonly objectFit?:
    | "contain"
    | "cover"
    | "fill"
    | "none"
    | "scale-down"
    | "initial";
  readonly className?: string;
  readonly loading?: "eager" | "lazy";
  readonly qualityDpr1?: number;
  readonly qualityDpr2?: number;
  readonly qualityDpr3?: number;
}

const StyledImage = styled(CWImage, {
  shouldForwardProp(property: string) {
    return property !== "focalPoints";
  },
})<
  Omit<CWImageProps, "objectPosition"> & {
    focalPoints: Required<
      ResponsiveThemeData<{
        x: number;
        y: number;
      }>
    >;
  }
>(({ focalPoints }) => {
  return css`
    object-position: ${convertFocalPointToCssObjectPosition(
      focalPoints.mobile
    )};

    ${MediaQueries.tablet} {
      object-position: ${convertFocalPointToCssObjectPosition(
        focalPoints.tablet
      )};
    }
    ${MediaQueries.desktop} {
      object-position: ${convertFocalPointToCssObjectPosition(
        focalPoints.desktop
      )};
    }
    ${MediaQueries.desktopWide} {
      object-position: ${convertFocalPointToCssObjectPosition(
        focalPoints.desktopWide
      )};
    }
  `;
});

const Image = ({
  imageSet,
  widths,
  objectFit = "cover",
  className,
  loading,
  qualityDpr1,
  qualityDpr2,
  qualityDpr3,
}: ImageProps) => {
  const { name: deviceName } = useMediaQuery();
  const { sources, src, alt } = getResponsiveImageData({
    imageSet,
    widths,
    deviceName,
    qualityDpr1,
    qualityDpr2,
    qualityDpr3,
  });

  return (
    <Picture className={className}>
      {sources.map(({ media, srcSet, id }) => {
        return <source key={id} srcSet={srcSet} media={media} />;
      })}

      <StyledImage
        stretch
        loading={loading}
        src={src}
        alt={alt}
        objectFit={objectFit}
        focalPoints={{
          mobile: imageSet.mobile.focalPoint,
          tablet: imageSet.tablet.focalPoint,
          desktop: imageSet.desktop.focalPoint,
          desktopWide: imageSet.desktopWide.focalPoint,
        }}
      />
    </Picture>
  );
};

export default Image;
