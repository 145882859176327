import React, {
  createContext,
  type ReactNode,
  useContext,
  useState,
} from "react";

interface VideoAutoplayContextState {
  isGlobalPause: boolean;
  setIsGlobalPause: (state: boolean) => void;
}

const VideoAutoplayContext = createContext<
  VideoAutoplayContextState | undefined
>(undefined);

const useVideoGlobalPause = (): VideoAutoplayContextState => {
  const videoGlobalPauseContext = useContext(VideoAutoplayContext);
  if (!videoGlobalPauseContext) {
    throw new Error(
      "useVideoGlobalPause can only be used inside a VideoAutoplayProvider"
    );
  }

  return videoGlobalPauseContext;
};

const VideoAutoplayProvider = ({
  children,
}: {
  readonly children?: ReactNode;
}) => {
  const [isGlobalPause, setIsGlobalPause] = useState(false);

  return (
    <VideoAutoplayContext.Provider
      value={{
        isGlobalPause,
        setIsGlobalPause,
      }}
    >
      {children}
    </VideoAutoplayContext.Provider>
  );
};

export {
  type VideoAutoplayContextState,
  VideoAutoplayContext,
  useVideoGlobalPause,
  VideoAutoplayProvider,
};
