import { ParsedButton, ParsedImageSet } from "../types";
import styled from "@emotion/styled";
import {
  ButtonVariants,
  Colors,
  Grid,
  GridColumn,
  Heading,
  Opacities,
  Paragraph,
  Section,
  Spacings,
  withOpacity,
} from "@polestar/component-warehouse-react";

import Button from "../Button/Button";
import Image from "../Image/Image";

export interface AssetCardItem {
  label?: string;
  title: string;
  image: ParsedImageSet;
  button: ParsedButton;
}

export interface AssetCardsProps {
  /**
   * Array of card data to display
   */
  cards: AssetCardItem[];
  /**
   * Section ID for HTML anchor
   */
  sectionId?: string;
}

const StyledGridColumn = styled(GridColumn)`
  height: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  padding: ${Spacings.large};
  border: 1px solid ${withOpacity(Colors.black, Opacities.low)};
`;

const StyledButton = styled(Button)`
  position: initial;
  /* Creates a clickable overlay for the entire card while keeping the button visually in place */
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: ${withOpacity(Colors.black, Opacities.medium)};
`;

/**
 * AssetCards component displays a grid of cards with image, title, optional label, and button.
 * Each card is fully clickable through the button's area.
 */
const AssetCards = ({ cards, sectionId }: AssetCardsProps) => {
  return (
    <Section id={sectionId} data-testid="asset-cards-section">
      <Grid>
        {cards.length <= 2 && (
          <GridColumn
            data-testid="asset-cards-spacer"
            span={{ mobile: "hidden", tablet: "hidden", desktop: 4 }}
          />
        )}

        {cards.map((card, index) => {
          const { button } = card;

          return (
            <StyledGridColumn
              key={index}
              span={{ mobile: 4, tablet: 4, desktop: 4 }}
            >
              <Card>
                <span>
                  {card.label && (
                    <StyledParagraph>{card.label}</StyledParagraph>
                  )}
                  <Heading level={3}>{card.title}</Heading>
                </span>
                {card.image && <Image imageSet={card.image} />}
                <StyledButton
                  key={button.id}
                  label={button.label}
                  href={button.href}
                  variant={ButtonVariants.text}
                  icon={button.icon}
                  openInNewTab={button.openInNewTab}
                  dataLayer={button.dataLayer}
                />
              </Card>
            </StyledGridColumn>
          );
        })}
      </Grid>
    </Section>
  );
};

export default AssetCards;
