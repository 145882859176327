/**
 * Converts common image properties into a source set. for use in an <img> tag.
 */
const getSourceSet = (
  image: { id: string; url: string },
  width: number,
  breakpoint: number,
  qualityDpr1: number,
  qualityDpr2: number,
  qualityDpr3: number
) => {
  return {
    id: `${image.id}-${breakpoint}-${width}`,
    media: `(min-width: ${breakpoint}px)`,
    srcSet: `${image.url}?q=${qualityDpr1}&dpr=1&w=${width} 1x, ${image.url}?q=${qualityDpr2}&dpr=2&w=${width} 2x, ${image.url}?q=${qualityDpr3}&dpr=3&w=${width} 3x`,
  };
};

export default getSourceSet;
